import { CSSProperties } from 'react';

import { useLocalization } from '~src/hooks/useLocalization';
import { SolarPanel } from '~src/types';
import { formatCurrency } from '~src/util';

import { SuggestedProduct } from './SuggestedProduct';

import { priceEntry, suggestedProduct } from './SuggestedProducts.module.css';

type SuggestedProductPackageProps = {
  products?: (Pick<SolarPanel, 'brand' | 'imageUrl' | 'model' | 'price'> & { quantity?: number })[];
  price?: number;
  style?: CSSProperties;
};

export const SuggestedProductPackage = ({ products, price, style }: SuggestedProductPackageProps) => {
  const translate = useLocalization();

  if (!products?.length) {
    return null;
  }

  const totalPrice = price ?? 0;

  return (
    <div className={suggestedProduct} style={style}>
      {products.map(product => (
        <SuggestedProduct key={`${product.brand} ${product.model}`} product={product} />
      ))}

      {totalPrice > 0 && (
        <>
          <div />
          <div className={priceEntry}>
            <p>{translate.PRICE_ALL_INCLUSIVE}</p>

            <p>
              <b>{formatCurrency(totalPrice, { decimals: 0 })}</b>
            </p>
          </div>
        </>
      )}
    </div>
  );
};
