import logo from '~src/assets/images/bodil-logo-contrast.png';
import igLogo from '~src/assets/images/ig.png';
import { useLeadOrigin } from '~src/hooks/useLeadOrigin';

import { header, igLogo as igLogoStyle, logo as logoStyle } from './Header.module.css';

export const Header = () => {
  const { leadOrigin } = useLeadOrigin();

  return (
    <header className={header}>
      <img src={logo} className={logoStyle} alt='logo' />
      {leadOrigin === 'installatorgruppen' && <img src={igLogo} className={igLogoStyle} alt='ig-logo' />}
    </header>
  );
};
