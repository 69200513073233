import { useState } from 'react';

import { PinIcon } from '~src/assets/icons';
import { useLocalization } from '~src/hooks';
import { useDawaAutoComplete } from '~src/services/useDawaAddresses';
import { AddressInput } from '~src/types';
import { toAddressFromAddressDescription, toAddressFromDAWAAddress } from '~src/util';

import { AutoSuggest } from '../AutoSuggest';

type Props = {
  onSelect: (address: AddressInput) => void;
};

export const AddressSearch = ({ onSelect }: Props) => {
  const [search, setSearch] = useState('');

  const translate = useLocalization();
  const { suggestions } = useDawaAutoComplete(search);

  return (
    <AutoSuggest
      autoFocus
      placeholder={translate.TYPE_ADDRESS}
      onChange={(newSearch?: string) => {
        setSearch(newSearch ?? '');
      }}
      onSelect={async item => {
        if (!item) {
          return;
        }

        const { forslagstekst, type, data } = item;

        setSearch(`${forslagstekst} `);

        if (type === 'adgangsadresse') {
          const addressResult = await toAddressFromAddressDescription(forslagstekst);

          if (addressResult) {
            onSelect(addressResult);
          }
        }

        if (type === 'adresse') {
          const newAddress = toAddressFromDAWAAddress(data);

          onSelect(newAddress!);
        }
      }}
      suggestions={suggestions.map(item => ({ ...item, name: item.forslagstekst }))}
      value={search}
      icon={<PinIcon />}
    />
  );
};
