import { ArrowIcon, HouseIcon } from '~src/assets/icons';
import { useLocalization, useResidenceState } from '~src/hooks';
import { formatAddress } from '~src/util';

import { Button } from '../Button';

import { addressLabel, backToAddressButton, backToAddressRow } from './BackToAddressPage.module.css';

type Props = {
  onBack?: () => void;
};

export const BackToAddressPage = ({ onBack }: Props) => {
  const translate = useLocalization();
  const { address } = useResidenceState();

  return (
    <div className={backToAddressRow}>
      <Button variant='link' onClick={onBack} className={backToAddressButton}>
        <ArrowIcon />

        <p>{translate.CHOOSE_NEW_ADDRESS}</p>
      </Button>

      <p className={addressLabel}>
        <HouseIcon />

        {formatAddress(address ?? {})}
      </p>
    </div>
  );
};
