import { useEnvironment } from '~src/hooks/useEnvironment';
import { Script } from '~src/types';

import { useCookieConsent } from './useCookieConsent';
import { useGtagConsent } from './useGtagConsent';

const GTAG_SCRIPTS = [
  { defer: true, src: `/scripts/gtag-calculator-analytics.js` },
  { defer: true, src: `/scripts/gtag-ads.js` },
];

const CALCULATOR_TRACKING_SCRIPTS = [
  { defer: true, src: `/scripts/clarity.js` },
  { defer: true, src: '/scripts/gclid.js' },
  { defer: true, src: '/scripts/gtm.js' },
  { defer: true, src: '/scripts/utm.js' },
];

export const useTrackingScripts = (): Script[] => {
  const { isDebug, isProduction } = useEnvironment();

  const { isAnalyticsAllowed, isAdvertisementAllowed } = useCookieConsent();

  useGtagConsent({
    adStorage: isAdvertisementAllowed,
    adPersonalization: isAdvertisementAllowed,
    analyticsStorage: isAnalyticsAllowed,
    adUserData: isAdvertisementAllowed,
  });

  if (isDebug || !isProduction) {
    return [];
  }

  if (!isAnalyticsAllowed) {
    return GTAG_SCRIPTS;
  }

  return [...CALCULATOR_TRACKING_SCRIPTS, ...GTAG_SCRIPTS];
};
