import { ArrowIcon } from '~src/assets/icons';

import { Button } from '../Button';

import { backButton, backButtonRow } from './BackButton.module.css';

type Props = {
  onBack?: () => void;
  buttonText?: string;
};

export const BackButton = ({ onBack, buttonText }: Props) => (
  <div className={backButtonRow}>
    <Button variant='link' onClick={onBack} className={backButton}>
      <ArrowIcon />

      <p>{buttonText}</p>
    </Button>
  </div>
);
