import { useEffect } from 'react';

import { CarouselQuestion } from '~src/components/QuestionCarousel/CarouselQuestion';
import { useOfferTypeQuestion } from '~src/components/QuestionCarousel/useOfferQuestions';
import { useAnalytics, useCalculatorFlowState, useOverrideProductType } from '~src/hooks';
import { useCalculatorAnswersState } from '~src/hooks/useCalculatorAnswersState';
import { PageProps, ProductType } from '~src/types';

import { offerTypeQuestionPage } from './OfferTypeQuestionPage.module.css';

export const OfferTypeQuestionPage = ({ onProceed }: PageProps) => {
  const { setDesiredProduct } = useCalculatorFlowState();
  const { answers, setAnswers } = useCalculatorAnswersState();
  const { productType } = useOverrideProductType();
  const { productTypesChosen } = useAnalytics();

  const offerTypeQuestion = useOfferTypeQuestion();

  const onAnswerQuestion = (key: string, answer: string | boolean | number) => {
    const newAnswers = { ...answers, [key]: { ...answers[key], value: answer } };

    setDesiredProduct((answer as ProductType) ?? 'both');
    setAnswers(newAnswers);
    onProceed?.();
  };

  useEffect(
    function proceedWithProductType() {
      if (productType) {
        onAnswerQuestion(offerTypeQuestion.key, productType);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productType]
  );

  return (
    <div className={offerTypeQuestionPage}>
      <CarouselQuestion
        question={offerTypeQuestion}
        key={offerTypeQuestion.text}
        onAnswer={value => {
          const type = value as ProductType;

          productTypesChosen(type);
          onAnswerQuestion(offerTypeQuestion.key, type);
        }}
      />
    </div>
  );
};
