import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { location } from '~src/util';

type StateStore = {
  isDebug: boolean;
  isLocalhost: boolean;
  isStaging: boolean;
  isProduction: boolean;
};

const { href } = location ?? {};

export const useEnvironment = create(
  persist<StateStore>(
    () => ({
      isDebug: false,
      isLocalhost: !!href?.includes('localhost'),
      isStaging: !!href?.includes('staging'),
      isProduction: !href?.includes('localhost') && !href?.includes('staging'),
    }),
    {
      name: 'environment-state',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
