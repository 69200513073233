import { useEffect } from 'react';

import { AddressSearch } from '~src/components/AddressSearch';
import { BackButton } from '~src/components/BackButton';
import { Loader } from '~src/components/Loader';
import { useAnalytics, useLocalization, useOverrideAddressById, useResidenceState } from '~src/hooks';
import { AddressInput, PageProps } from '~src/types';

import { addressField, addressSearch, page, title } from './AddressSearchPage.module.css';

export const AddressSearchPage = ({ onProceed, onBack }: PageProps) => {
  const translate = useLocalization();
  const { address: overrideAddress, isLoading } = useOverrideAddressById();
  const { setAddress } = useResidenceState();
  const { addressSearched } = useAnalytics();

  const onSelect = (address: AddressInput) => {
    setAddress(address);
    addressSearched();
    onProceed?.();
  };

  useEffect(
    function proceedWithAddress() {
      if (overrideAddress) {
        setAddress(overrideAddress);
        onProceed?.();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [overrideAddress]
  );

  if (isLoading) {
    return (
      <div className={page}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={page}>
      <BackButton buttonText={translate.CHOOSE_PRODUCT} onBack={onBack} />

      <div className={addressSearch}>
        <h1 className={title}>{translate.WHAT_IS_YOUR_ADDRESS}</h1>

        <div className={addressField}>
          <AddressSearch onSelect={onSelect} />
        </div>
      </div>
    </div>
  );
};
