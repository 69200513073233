import { clsx } from 'clsx';
import { ReactNode } from 'react';

import { formError } from './FormError.module.css';

type FormErrorProps = {
  children?: ReactNode;
  className?: string;
};

export const FormError = ({ children, className }: FormErrorProps) => (
  <p className={clsx(className, formError)}>{children}</p>
);
