import { Answers } from '~src/components/QuestionCarousel';
import { useCalculatorFlowState } from '~src/hooks/useCalculatorFlowState';
import { useResidenceState } from '~src/hooks/useResidenceState';
import { FuelType, ProductType } from '~src/types';
import { DEFAULT_ELECTRIC_CAR, DEFAULT_RESIDENCE, estimatePrimaryHeating, fuelOptions } from '~src/util';

export const useUpdateStateWithAnswers = () => {
  const { setDesiredProduct } = useCalculatorFlowState();
  const { residence, setResidence, setElectricCar } = useResidenceState();

  return (answers: Answers) => {
    const { offerType, fuelType, heatConsumption, electricCar, electricityConsumption } = answersToValues(answers);

    setElectricCar(electricCar);
    setDesiredProduct((offerType as ProductType) ?? 'both');

    const updatedResidence = { ...DEFAULT_RESIDENCE, ...(residence ?? {}) };

    const heating = estimatePrimaryHeating(
      fuelType,
      updatedResidence.areaHeated ?? 0,
      updatedResidence.builtYear ?? 0,
      fuelOptions
    );

    const annualUsage = (heatConsumption || heating.annualUsage || residence?.primaryHeating?.annualUsage) ?? 0;

    setResidence({
      ...updatedResidence,
      primaryHeating: {
        ...residence?.primaryHeating,
        ...heating,
        annualUsage,
        fuelType,
      },
      energyExpenditure: electricityConsumption,
    });
  };
};

const answersToValues = (answers: Answers) => {
  const { offerType, fuelType, heatConsumption, electricCar, electricityConsumption } = answers;

  return {
    offerType: offerType?.value as ProductType,
    fuelType: fuelType?.value as FuelType,
    heatConsumption: heatConsumption?.value as number,
    electricCar: electricCar?.value ? DEFAULT_ELECTRIC_CAR : undefined,
    electricityConsumption: electricityConsumption?.value as number,
  };
};
