import { Helmet } from 'react-helmet';

import { useAdvertisementScripts, useCustomerChatScripts, useTrackingScripts } from '~src/hooks';

export const ThirdPartyScripts = () => {
  const trackingScripts = useTrackingScripts();
  const advertisementScripts = useAdvertisementScripts();
  const chatScripts = useCustomerChatScripts();

  const scripts = [...trackingScripts, ...advertisementScripts, ...chatScripts];

  return (
    <Helmet>
      <script
        defer
        id='cookieyes'
        type='text/javascript'
        src='https://cdn-cookieyes.com/client_data/74ccc4d9b597d05ce0a6b8bd/script.js'
      />

      {scripts.map(({ async, defer, src }) => (
        <script async={async} defer={defer} key={src} src={src} />
      ))}
    </Helmet>
  );
};
