import { clsx } from 'clsx';

import { ArrowIcon } from '~src/assets/icons';
import { arrayWithLength } from '~src/util';

import { Button } from '../Button';

import {
  activeDot,
  dot,
  dotWrapper,
  hidden,
  navigation,
  nextQuestionButton,
  questionNavigationButton,
} from './carousel.module.css';

type CarouselNavigationProps = {
  progressIndex?: number;
  setProgressIndex?: (index: number) => void;
  numberOfSteps?: number;
  disableForward?: boolean;
};

export const CarouselNavigation = ({
  progressIndex = 0,
  setProgressIndex,
  numberOfSteps = 0,
  disableForward,
}: CarouselNavigationProps) => {
  const backDisabled = progressIndex === 0;
  const forwardDisabled = disableForward || progressIndex === numberOfSteps - 1;

  return (
    <div className={navigation}>
      <div className={dotWrapper}>
        <Button
          disabled={backDisabled}
          onClick={() => setProgressIndex?.(progressIndex - 1)}
          className={clsx(questionNavigationButton, backDisabled && hidden)}
          variant='link'
        >
          <ArrowIcon />
        </Button>

        {arrayWithLength(numberOfSteps, i => i).map(index => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            key={`dot - ${index}`}
            onClick={() => (!forwardDisabled || index < progressIndex) && setProgressIndex?.(index)}
            className={clsx(dot, progressIndex === index && activeDot)}
          />
        ))}

        <Button
          disabled={forwardDisabled}
          onClick={() => setProgressIndex?.(progressIndex + 1)}
          className={clsx(questionNavigationButton, nextQuestionButton, forwardDisabled && hidden)}
          variant='link'
        >
          <ArrowIcon />
        </Button>
      </div>
    </div>
  );
};
