import { useAddressSavingsQuery } from '~src/gql';
import { ElectricCarInput, FuelType, HouseInputWithUsage, ResidenceOutput } from '~src/types';
import { deepNullToUndefined } from '~src/util';

import { graphQLClient } from '../client';

type CalculateAddressSavingsInput = {
  dawaId?: string;
  residence?: ResidenceOutput;
  electricCar?: ElectricCarInput;
};

export const useCalculateAddressSavings = ({
  dawaId,
  residence: residenceOutput,
  electricCar,
}: CalculateAddressSavingsInput) => {
  const { primaryHeating, energyExpenditure } = residenceOutput ?? {};
  const { fuelType, annualUsage } = primaryHeating ?? {};

  const residence: HouseInputWithUsage | undefined =
    fuelType && annualUsage !== undefined && annualUsage > 0
      ? {
          electricCar,
          fuelType: fuelType as FuelType,
          annualUsage,
          energyExpenditure,
        }
      : undefined;

  const { data, isFetching } = useAddressSavingsQuery(
    graphQLClient,
    { dawaId: dawaId!, residence },
    { enabled: !!dawaId }
  );

  return {
    savings: deepNullToUndefined(data?.addressSavings),
    isFetching,
  };
};

export type AddressSavingsResult = ReturnType<typeof useCalculateAddressSavings>['savings'];
