import pick from 'lodash/pick';

import { OfferInput } from '~src/types';

type Product = { id: number };

type Products = {
  heatPumpIndoorUnit?: Product;
  heatPumpOutdoorUnit?: Product;
  inverter?: Product;
  solarPanel?: Product;
  battery?: Product;
};

export const productsToHeatPumpOfferInput = (products?: Products): OfferInput | undefined => {
  const { heatPumpIndoorUnit, heatPumpOutdoorUnit } = products ?? {};

  if (!heatPumpOutdoorUnit) {
    return undefined;
  }

  return {
    heatPumpIndoorProduct: toProduct(heatPumpIndoorUnit),
    heatPumpOutdoorProduct: toProduct(heatPumpOutdoorUnit),
  };
};

export const productsToSolarOfferInput = (products?: Products): OfferInput | undefined => {
  const { inverter, battery, solarPanel } = products ?? {};

  if (!inverter || !solarPanel) {
    return undefined;
  }

  return {
    inverterProduct: toProduct(inverter),
    batteryProduct: toProduct(battery),
    solarPanelProduct: toSolarPanelList(solarPanel),
  };
};

const toProduct = <T>(product?: T): T | undefined =>
  product ? (pick(product, ['id', 'price', 'buyPrice']) as T) : undefined;

const toSolarPanelList = <T extends Product & { quantity?: number }>(product?: T): T | undefined =>
  product ? (pick(product, ['id', 'quantity', 'price', 'buyPrice']) as T) : undefined;
