import { useEffect } from 'react';

import { BackToAddressPage } from '~src/components/BackToAddressPage';
import { Loader } from '~src/components/Loader';
import { Answers, QuestionCarousel } from '~src/components/QuestionCarousel';
import { useOfferQuestions } from '~src/components/QuestionCarousel/useOfferQuestions';
import { useResidenceState } from '~src/hooks/useResidenceState';
import { useResidenceForAddress } from '~src/services/useResidenceForAddress';
import { PageProps } from '~src/types';

import { useUpdateStateWithAnswers } from './useUpdateStateWithAnswers';

import { container } from './QuestionsPage.module.css';

export const QuestionsPage = ({ onProceed, onBack }: PageProps) => {
  const { residence, setResidence, defaultResidence } = useResidenceState();
  const { isFetching } = useResidenceForAddress();

  const { getQuestions } = useOfferQuestions(residence, defaultResidence);
  const updateStateWithAnswers = useUpdateStateWithAnswers();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setResidence(residence), [residence]);

  const onQuestionsDone = (answers: Answers) => {
    if (!residence) {
      return;
    }

    updateStateWithAnswers(answers);
    onProceed?.();
  };

  if (isFetching) {
    return (
      <div className={container}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={container}>
      <BackToAddressPage onBack={onBack} />

      <QuestionCarousel getQuestions={getQuestions} onDone={onQuestionsDone} />
    </div>
  );
};
