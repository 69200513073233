import { useEffect, useState } from 'react';

import { fetchDawaAddressById } from '~src/services/useDawaAddresses';
import { AddressInput } from '~src/types';
import { toAddressFromDAWAAddress } from '~src/util';

import { useOverrideState } from './useOverrideState';

export const useOverrideAddressById = () => {
  const { didOverrideAddress, setDidOverrideAddress } = useOverrideState();

  const [address, setAddress] = useState<AddressInput>();
  const [isLoading, setIsLoading] = useState(true);

  const parameters = new URLSearchParams(globalThis.location?.search.toLowerCase());
  const addressId = parameters?.get('addressid') ?? undefined;

  useEffect(
    function findAddress() {
      async function findAddressAsync() {
        if (didOverrideAddress || !addressId) {
          setIsLoading(false);
          return;
        }

        try {
          const data = await fetchDawaAddressById(addressId);
          const newAddress = data ? toAddressFromDAWAAddress(data) : undefined;

          if (newAddress) {
            setAddress(newAddress);
          }
        } catch (error) {
          setAddress(undefined);
        } finally {
          setIsLoading(false);
          setDidOverrideAddress();
        }
      }

      findAddressAsync();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addressId]
  );

  return {
    isLoading,
    address,
  };
};
