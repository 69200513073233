import { useRef } from 'react';

import { Loader } from '~src/components/Loader';
import { SavingsWidget } from '~src/components/SavingsWidget';
import { useCalculatorFlowState } from '~src/hooks';
import { useContactInformationFormSubmit } from '~src/hooks/useContactInformationForm';
import { useResidenceState } from '~src/hooks/useResidenceState';
import { useCalculateAddressSavings } from '~src/services/useCalculateAddressSavings';
import { pickSavingsApplicableOffers, pickSavingsResult } from '~src/util';

import { BookingSection } from './BookingSection';
import { ContactInformationForm } from './ContactInformationFormView';
import { SuggestedProductList } from './SuggestedProducts';

import { initialSection, outer } from './EstimateConfirmationView/EstimateConfirmationView.module.css';
import { backgroundImage, page } from './EstimatePage.module.css';

export const EstimatePageNordea = () => {
  const { address, residence, electricCar } = useResidenceState();

  const ref = useRef<HTMLDivElement>(null);

  const { savings, isFetching } = useCalculateAddressSavings({
    dawaId: address?.id,
    residence,
    electricCar,
  });

  const { desiredProduct, userInfoSubmitted } = useCalculatorFlowState();

  const savingsResult = pickSavingsResult(savings, desiredProduct);
  const onSubmit = useContactInformationFormSubmit(savingsResult);

  const result = pickSavingsResult(savings, desiredProduct);
  const offers = pickSavingsApplicableOffers(savings, desiredProduct);

  if (isFetching) {
    return <Loader />;
  }

  return (
    <div ref={ref} className={page}>
      <div className={backgroundImage} />
      <div className={outer}>
        <div className={initialSection}>
          <SavingsWidget showSavingsText savingsResult={result} />

          {userInfoSubmitted ? <BookingSection /> : <ContactInformationForm onSubmit={onSubmit} />}
        </div>

        {userInfoSubmitted && <SuggestedProductList offers={offers} />}
      </div>
    </div>
  );
};
