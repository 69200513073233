import { SolarPanel } from '~src/types';
import { getSizedImageUrl } from '~src/util';

import { productImage, productInformation } from './SuggestedProducts.module.css';

type SuggestedProductProps = {
  product: Pick<SolarPanel, 'brand' | 'imageUrl' | 'model' | 'price'> & { quantity?: number };
};

export const SuggestedProduct = ({ product }: SuggestedProductProps) => {
  const { model, brand, imageUrl, quantity } = product;
  const numberOfProducts = quantity ? `${quantity} x ` : '';
  const name = `${brand} ${model}`;

  const resizedImage = getSizedImageUrl(imageUrl, { width: 120, height: 120 });

  return (
    <>
      {imageUrl ? (
        <img className={productImage} src={resizedImage} alt={quantity ? 'solpanel' : 'varmepumpe'} />
      ) : (
        <div />
      )}

      <div className={productInformation}>
        <p>
          {numberOfProducts}

          {name}
        </p>
      </div>
    </>
  );
};
