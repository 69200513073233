/* eslint-disable react/jsx-props-no-spreading */
import { SubmitHandler, useForm } from 'react-hook-form';

import energyReport from '~src/assets/images/energy-report.png';
import { Button } from '~src/components/Button';
import { FormError } from '~src/components/FormError';
import { PRIVACY_POLICY_URL } from '~src/constants';
import { useDataState } from '~src/hooks/useDataState';
import { LEAD_ORIGINS, useLeadOrigin } from '~src/hooks/useLeadOrigin';
import { useLocalization } from '~src/hooks/useLocalization';
import { ContactInformation } from '~src/types';

import {
  energyReportImage,
  form as formStyle,
  formError,
  formTitle,
  input,
  inputWrapper,
  privacyLink,
  textBox,
} from './ContactInformationFormView.module.css';

type FormProps = {
  onSubmit: SubmitHandler<ContactInformation>;
};

export const ContactInformationForm = ({ onSubmit }: FormProps) => {
  const translate = useLocalization();
  const { leadOrigin } = useLeadOrigin();

  const { userInfo } = useDataState();

  const { register, handleSubmit, formState } = useForm<ContactInformation>({
    mode: 'onSubmit',
  });

  const { errors } = formState;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={formStyle} noValidate>
      <p className={formTitle}>{translate.RECEIVE_REPORT}</p>

      <div className={textBox}>{translate.ENERGY_REPORT}</div>

      <img src={energyReport} className={energyReportImage} alt='report' />

      <div className={inputWrapper}>
        <input
          className={input}
          placeholder={translate.NAME}
          defaultValue={userInfo?.name}
          {...register('name', { required: true, minLength: 2 })}
        />

        {errors.name && <FormError className={formError}>{translate.NAME_REQUIRED}</FormError>}
      </div>

      <div className={inputWrapper}>
        <input
          className={input}
          placeholder={translate.EMAIL}
          type='email'
          defaultValue={userInfo?.email}
          {...register('email', { required: true, pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/ })}
        />

        {errors.email && <FormError className={formError}>{translate.EMAIL_REQUIRED}</FormError>}
      </div>

      <div className={inputWrapper}>
        <input
          className={input}
          placeholder={translate.PHONE_NUMBER}
          type='tel'
          defaultValue={userInfo?.phoneNumber}
          {...register('phoneNumber', { required: true, pattern: /^(\+45)?([0-9]{8})$/ })}
        />

        {errors.phoneNumber && <FormError className={formError}>{translate.PHONE_NUMBER_REQUIRED}</FormError>}
      </div>

      <div className={textBox}>{getConsentText(leadOrigin)}</div>
      <div className={textBox}>{translate.CONTACT_INFORMATION_DISCLAIMER}</div>

      <Button type='submit' variant='callToAction'>
        {translate.SEE_CALCULATION}
      </Button>
    </form>
  );
};

const getConsentText = (leadOrigin?: string) => {
  if (leadOrigin === LEAD_ORIGINS.JYSKE_BANK) {
    return (
      <>
        Jeg giver hermed samtykke til, at Bodil Energi må kontakte mig pr. e-mail og telefonisk med en skræddersyet
        energirapport og tilbud baseret på de oplysninger, jeg har afgivet (navn, kontaktoplysninger, adresse,
        oplysninger om bolig). Bodil Energi kan efterfølgende videregive oplysninger om resultatet af mine initiativer
        og mine aftaler med Bodil Energi til Jyske Bank med henblik på mulig finansiering af tilbuddet. Jeg kan til
        enhver tid tilbagekalde mit samtykke. Nærmere information om Bodil Energis behandling af personoplysninger
        fremgår af{' '}
        <a className={privacyLink} target='_blank' href={PRIVACY_POLICY_URL} rel='noreferrer'>
          privatlivspolitikken
        </a>
        .
      </>
    );
  }

  return (
    <>
      Ved at fortsætte giver jeg samtykke til, at Bodil Energi må kontakte mig pr. e-mail og telefonisk med en
      skræddersyet energirapport og tilbud baseret på de oplysninger, jeg har afgivet (navn, kontaktoplysninger,
      adresse, oplysninger om bolig). Jeg accepterer, at Energirapporten kan indeholde information om
      finansieringsmuligheder. Jeg kan til enhver tid tilbagekalde mit samtykke. Nærmere information om Bodil Energis
      behandling af personoplysninger fremgår af{' '}
      <a className={privacyLink} target='_blank' href={PRIVACY_POLICY_URL} rel='noreferrer'>
        privatlivspolitikken
      </a>
      .
    </>
  );
};
