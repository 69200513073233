import { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';

import { Header } from '../Header';

import { border, content } from './Layout.module.css';

import 'react-toastify/dist/ReactToastify.css';

type Props = {
  children?: ReactNode;
};

export const DefaultLayout = ({ children }: Props) => (
  <div className={border}>
    <Header />

    <main className={content}>{children}</main>

    <ToastContainer autoClose={false} closeOnClick limit={5} position='top-right' />
  </div>
);
