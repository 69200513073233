import { useEffect, useRef } from 'react';

export const useAutoFocus = <T extends HTMLElement>(enabled: boolean) => {
  const ref = useRef<T>(null);

  useEffect(
    function focusOnLoad() {
      if (ref.current && enabled) {
        ref.current.focus();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current, enabled]
  );

  return ref;
};
