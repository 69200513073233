import { useInsertAndSendOffersMutation } from '~src/gql';
import { useLocalization } from '~src/hooks';
import { handleNetworkError } from '~src/util';

import { graphQLClient } from './client';

export const useInsertAndSendOffers = () => {
  const translate = useLocalization();

  const { mutateAsync, isLoading } = useInsertAndSendOffersMutation(graphQLClient, {});

  const insertAndSendOffers = async (variables: Parameters<typeof mutateAsync>[0]) => {
    const mutation = await mutateAsync(variables, { onError: handleNetworkError(translate) });

    return mutation.insertAndSendOffers;
  };

  return { insertAndSendOffers, isLoading };
};
