import { forwardRef } from 'react';

import { ApplicableProducts } from '~src/types';
import { filterFalsy } from '~src/util';

import { SuggestedProductPackage } from './SuggestedProductPackage';

import { suggestedProducts } from './SuggestedProducts.module.css';

type SuggestedProductListProps = {
  hidden?: boolean;
  offers?: ApplicableProducts;
};

export const SuggestedProductList = forwardRef<HTMLDivElement, SuggestedProductListProps>(function SuggestedProducts(
  { hidden = false, offers },
  ref
) {
  const { heatPumpOffer, solarOffer } = offers ?? {};

  const { heatPumpOutdoorUnit, heatPumpIndoorUnit, price: heatPumpPrice } = heatPumpOffer ?? {};
  const { solarPanel, inverter, battery, price: solarPrice } = solarOffer ?? {};

  const hiddenStyle = hidden ? { display: 'none' as const } : {};

  return (
    <div className={suggestedProducts} ref={ref}>
      {!!heatPumpOutdoorUnit && (
        <SuggestedProductPackage
          products={filterFalsy([heatPumpOutdoorUnit, heatPumpIndoorUnit])}
          price={heatPumpPrice}
          style={hiddenStyle}
        />
      )}

      {!!solarPanel && (
        <SuggestedProductPackage
          products={filterFalsy([solarPanel, inverter, battery])}
          price={solarPrice}
          style={hiddenStyle}
        />
      )}
    </div>
  );
});
