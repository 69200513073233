import { useEnvironment } from '~src/hooks';
import { Script } from '~src/types';

const HOMEPAGE_CHAT_SCRIPTS = [{ defer: true, src: '/scripts/relatel.js' }];

export const useCustomerChatScripts = (): Script[] => {
  const { isDebug, isProduction } = useEnvironment();

  if (isDebug || !isProduction) {
    return [];
  }

  return HOMEPAGE_CHAT_SCRIPTS;
};
