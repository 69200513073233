import clsx from 'clsx';
import { CSSProperties } from 'react';

import { CarouselAnswer } from './CarouselAnswer';
import { Question } from './types';

import { question as questionStyle, questionDescription, questionTitle } from './carousel.module.css';

type QuestionProps = {
  question: Question;
  style?: CSSProperties;
  onAnswer: (value: string | number | boolean) => void;
  className?: string;
};

export const CarouselQuestion = ({ question, style, onAnswer, className }: QuestionProps) => {
  const { text, description } = question;

  return (
    <div className={clsx(questionStyle, className)} style={style}>
      <h1 className={questionTitle}>{text}</h1>

      {description && <p className={questionDescription}>{description}</p>}

      <CarouselAnswer question={question} onAnswer={onAnswer} />
    </div>
  );
};
