import { useQuery } from '@tanstack/react-query';

import { DAWAAutocompleteSuggestion } from '~src/types';
import { deepNullToUndefined, fetchAddressSuggestions, fetchData } from '~src/util';

export const useDawaAutoComplete = (search: string) => {
  const { data, isLoading } = useQuery(['dawaAutoComplete', search], () => fetchAutoComplete(search), {
    enabled: search.length > 2,
  });

  return { suggestions: deepNullToUndefined(data) ?? [], isLoading };
};

const fetchAutoComplete = async (search: string): Promise<Array<DAWAAutocompleteSuggestion> | undefined> =>
  fetchAddressSuggestions(search);

const FETCH_OPTIONS: RequestInit = {
  headers: { Connection: 'keep-alive' },
  method: 'get',
};

export const fetchGet = async <T>(url: string, search = '') => {
  if (!search) {
    return [];
  }

  return fetchData(() => fetch(`${url}${search}`, FETCH_OPTIONS)) as T;
};
