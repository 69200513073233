import { ProductType, TranslationRecord } from '~src/types';

type ProductTranslationRecord = Pick<
  TranslationRecord,
  'HEAT_PUMP' | 'SOLAR_PANEL_INSTALLATION' | 'HEAT_PUMP_AND_SOLAR'
>;

export const formatProducts = (desiredProduct: ProductType, translations: ProductTranslationRecord) => {
  const heatPumpLowerCase = translations.HEAT_PUMP.toLowerCase();
  const solarPanelInstallationLowerCase = translations.SOLAR_PANEL_INSTALLATION.toLowerCase();

  if (desiredProduct === 'both') {
    return translations.HEAT_PUMP_AND_SOLAR;
  }

  return desiredProduct === 'solar' ? solarPanelInstallationLowerCase : heatPumpLowerCase;
};
