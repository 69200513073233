import { useEffect } from 'react';

import { useBbrLookupByAddressQuery } from '~src/gql';
import { useCalculatorFlowState } from '~src/hooks/useCalculatorFlowState';
import { useResidenceState } from '~src/hooks/useResidenceState';
import { graphQLClient } from '~src/services/client';
import { ResidenceOutput } from '~src/types';
import { deepNullToUndefined, DEFAULT_RESIDENCE, toValidAddressInput } from '~src/util';

export const useResidenceForAddress = () => {
  const { setCurrentStep } = useCalculatorFlowState();
  const { address, setResidence, setDefaultResidence } = useResidenceState();

  const addressInput = toValidAddressInput(address);

  const { data, isFetching } = useBbrLookupByAddressQuery(
    graphQLClient,
    { address: addressInput! },
    { enabled: !!addressInput }
  );

  const { bbrLookupByAddress: residenceData } = data ?? {};

  const residence: ResidenceOutput = {
    ...DEFAULT_RESIDENCE,
    ...deepNullToUndefined(residenceData),
  };

  useEffect(
    function updateResidence() {
      if (isFetching) {
        return;
      }

      setDefaultResidence(residence);
      setResidence(residence);

      if (residence?.type === 'apartment') {
        setCurrentStep('no-offer-fallback');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return { isFetching };
};
