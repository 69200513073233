/* eslint-disable camelcase */
import isFunction from 'lodash/isFunction';

import { ProductType } from '~src/types';

import { useLeadOrigin } from './useLeadOrigin';

const calculatorStepNames = {
  fuelType: 'heat_source',
  heatConsumption: 'heat_usage',
  electricityConsumption: 'electricity_usage',
  electricCar: 'electric_car',
};

type EventAction = 'products_chosen' | 'address_searched' | 'contact_info_submitted' | 'calculator_step_completed';

type ManualEvent =
  | 'estimate_page_go_to_booking'
  | 'estimate_page_go_to_calculator'
  | 'click_financing_toggle'
  | 'estimate_page_call_me';

// Any custom parameters needs to be explicitly added to Google Analytics for it to be tracked
// Add under admin -> data display -> custom definitions
type GoogleAnalyticsParameter = 'lead_origin' | 'product_type' | 'email' | 'step_name';

type GoogleAnalyticsParameters = Partial<Record<GoogleAnalyticsParameter, string | number | boolean | undefined>>;

type WindowWithTracking = typeof window & {
  gtag?: (...args: unknown[]) => void;
  fbq?: (...args: unknown[]) => void;
  dataLayer?: unknown[];
};

const trackEvent = (action: EventAction | ManualEvent, parameters?: GoogleAnalyticsParameters): void => {
  const { fbq, gtag, dataLayer } = (window as WindowWithTracking) ?? {};

  // GTM
  if (dataLayer && isFunction(dataLayer.push)) {
    dataLayer.push({ ...parameters, event: action });
  }

  // GTAG
  if (gtag && isFunction(gtag)) {
    gtag('event', action, parameters);
  }

  // META PIXEL
  if (fbq && isFunction(fbq)) {
    fbq('trackCustom', action, parameters);
  }
};

export const useAnalytics = () => {
  const { leadOrigin: lead_origin } = useLeadOrigin();

  return {
    trackEvent: (type: ManualEvent, parameters?: Omit<GoogleAnalyticsParameters, 'lead_origin'>) =>
      trackEvent(type, { ...parameters, lead_origin }),

    productTypesChosen: (productType: ProductType) =>
      trackEvent('products_chosen', { lead_origin, product_type: productType }),

    addressSearched: () => trackEvent('address_searched', { lead_origin }),

    stepCompleted: (stepKey: keyof typeof calculatorStepNames) =>
      trackEvent('calculator_step_completed', {
        step_name: calculatorStepNames[stepKey],
        lead_origin,
      }),

    contactInfoSubmitted: (email?: string) => trackEvent('contact_info_submitted', { email, lead_origin }),
  };
};
