import { useEffect, useState } from 'react';

import { ProductType } from '~src/types';

import { useOverrideState } from './useOverrideState';

export const useOverrideProductType = () => {
  const { didOverrideProductType, setDidOverrideProductType } = useOverrideState();

  const [productType, setProductType] = useState<ProductType>();

  const parameters = new URLSearchParams(globalThis.location?.search.toLowerCase());
  const inputProductType = parameters?.get('producttype') ?? undefined;

  useEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    function overrideProductType() {
      if (didOverrideProductType || !inputProductType) {
        return;
      }

      if (isValidProductType(inputProductType)) {
        setProductType(inputProductType);
        setDidOverrideProductType();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputProductType]
  );

  return {
    productType,
  };
};

const isValidProductType = (productType = ''): productType is ProductType =>
  ['heatPump', 'solar', 'both'].includes(productType);
