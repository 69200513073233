import { AddressInput } from '~src/types';

export {
  fetchAddressSuggestions,
  findAddress,
  findAddressById,
  findAddresses,
  toAddressFromAddressDescription,
  toAddressFromDAWAAddress,
} from '@bodilenergy/address';

export const toValidAddressInput = (input?: Partial<AddressInput>): AddressInput | undefined => {
  const { city, street, postalCode, houseNumber, floor, door } = input ?? {};
  if (!city || !street || !houseNumber || !postalCode) {
    return undefined;
  }

  return { city, street, postalCode, houseNumber, floor, door };
};
