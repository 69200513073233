import { useEffect, useRef, useState } from 'react';

import { useAnalytics, useCalculatorAnswersState } from '~src/hooks';

import { CarouselNavigation } from './CarouselNavigation';
import { CarouselQuestion } from './CarouselQuestion';
import { Answers, Question, QuestionKey } from './types';

import { container, outer } from './carousel.module.css';

type QuestionCarouselProps = {
  getQuestions: (answers?: Answers) => Question[];
  onDone: (answers: Answers) => void;
};

export const QuestionCarousel = ({ getQuestions, onDone }: QuestionCarouselProps) => {
  const { answers, setAnswers } = useCalculatorAnswersState();
  const { stepCompleted } = useAnalytics();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);

  const containerRef = useRef<HTMLDivElement>(null);

  const questions = getQuestions(answers);

  useEffect(function setInitialQuestionIndex() {
    if (currentQuestionIndex > -1) {
      return;
    }
    setCurrentQuestionIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAnswerQuestion = (key: QuestionKey, answer: string | boolean | number) => {
    const newAnswers = { ...answers, [key]: { ...answers[key], value: answer } };
    const newIndex = currentQuestionIndex + 1;
    if (key !== 'offerType') {
      stepCompleted(key);
    }

    setAnswers(newAnswers);
    const newQuestions = getQuestions(newAnswers);

    if (newIndex === newQuestions.length) {
      onDone(newAnswers);
      return;
    }

    setTimeout(() => setCurrentQuestionIndex(newIndex));
  };
  const currentQuestionHasNoAnswer = !answers?.[questions?.[currentQuestionIndex]?.key]?.value;

  return (
    <div className={outer}>
      <div className={container} ref={containerRef}>
        {questions.map((question, index) => (
          <CarouselQuestion
            question={question}
            key={question.text}
            onAnswer={value => onAnswerQuestion(question.key, value)}
            style={{
              transform: positionTransform(index, currentQuestionIndex),
              opacity: index === currentQuestionIndex ? '100%' : '0%',
            }}
          />
        ))}
      </div>

      <CarouselNavigation
        setProgressIndex={setCurrentQuestionIndex}
        progressIndex={currentQuestionIndex}
        numberOfSteps={questions.length}
        disableForward={currentQuestionHasNoAnswer}
      />
    </div>
  );
};

const positionTransform = (index: number, currentIndex: number) => {
  if (index > currentIndex) {
    return `translateX(200%)`;
  }

  if (index < currentIndex) {
    return `translateX(-300%)`;
  }

  return `translateX(-50%)`;
};
