import { useEnergyOptionsQuery } from '~src/gql';
import { deepNullToUndefined, mapConstantsToEnergyOptions } from '~src/util';

import { graphQLClient } from './client';

export const useEnergyOptions = () => {
  const { data } = useEnergyOptionsQuery(graphQLClient);

  return mapConstantsToEnergyOptions(deepNullToUndefined(data?.energyOptions ?? []));
};
