import Arrow from '~src/assets/icons/arrow.svg?react';
import Calendar from '~src/assets/icons/calendar.svg?react';
import Checkmark from '~src/assets/icons/checkmark.svg?react';
import Chevron from '~src/assets/icons/chevron.svg?react';
import Co2 from '~src/assets/icons/co2.svg?react';
import CurvedArrow from '~src/assets/icons/curvedArrow.svg?react';
import Furnace from '~src/assets/icons/furnace.svg?react';
import Gas from '~src/assets/icons/gas.svg?react';
import HeatPump from '~src/assets/icons/heatPump.svg?react';
import HeatPumpAndSolar from '~src/assets/icons/heatPumpAndSolar.svg?react';
import House from '~src/assets/icons/house.svg?react';
import Info from '~src/assets/icons/info.svg?react';
import Level1of5 from '~src/assets/icons/level1of5.svg?react';
import Level2of5 from '~src/assets/icons/level2of5.svg?react';
import Level3of5 from '~src/assets/icons/level3of5.svg?react';
import Level4of5 from '~src/assets/icons/level4of5.svg?react';
import Level5of5 from '~src/assets/icons/level5of5.svg?react';
import Lightning from '~src/assets/icons/lightning.svg?react';
import MoneyBag from '~src/assets/icons/moneyBag.svg?react';
import Oil from '~src/assets/icons/oil.svg?react';
import PiggyBank from '~src/assets/icons/piggyBank.svg?react';
import Pin from '~src/assets/icons/pin.svg?react';
import PriceTag from '~src/assets/icons/priceTag.svg?react';
import Radiator from '~src/assets/icons/radiator.svg?react';
import Solar from '~src/assets/icons/solar.svg?react';
import Straw from '~src/assets/icons/straw.svg?react';
import X from '~src/assets/icons/x.svg?react';

export const GasIcon = Gas;
export const HeatPumpIcon = HeatPump;
export const HeatPumpAndSolarIcon = HeatPumpAndSolar;
export const HouseIcon = House;
export const Level1of5Icon = Level1of5;
export const Level2of5Icon = Level2of5;
export const Level3of5Icon = Level3of5;
export const Level4of5Icon = Level4of5;
export const Level5of5Icon = Level5of5;
export const OilIcon = Oil;
export const PinIcon = Pin;
export const SolarIcon = Solar;
export const ArrowIcon = Arrow;
export const LightningIcon = Lightning;
export const RadiatorIcon = Radiator;
export const StrawIcon = Straw;
export const XIcon = X;
export const CheckmarkIcon = Checkmark;
export const FurnaceIcon = Furnace;
export const InfoIcon = Info;
export const PriceTagIcon = PriceTag;
export const CalendarIcon = Calendar;
export const PiggyBankIcon = PiggyBank;
export const MoneyBagIcon = MoneyBag;
export const Co2Icon = Co2;
export const CurvedArrowIcon = CurvedArrow;
export const ChevronIcon = Chevron;
