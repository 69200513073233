import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type StateStore = {
  didOverrideAddress: boolean;
  setDidOverrideAddress: () => void;

  didOverrideProductType: boolean;
  setDidOverrideProductType: () => void;
};

export const useOverrideState = create(
  persist<StateStore>(
    set => ({
      didOverrideAddress: false,
      setDidOverrideAddress: () => set({ didOverrideAddress: true }),

      didOverrideProductType: false,
      setDidOverrideProductType: () => set({ didOverrideProductType: true }),
    }),
    {
      name: 'override-state',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
