import { useCalculatorFlowState } from '~src/hooks/useCalculatorFlowState';
import { useResidenceForAddress } from '~src/services/useResidenceForAddress';

import { AddressSearchPage } from './AddressSearchPage';
import { EstimatePage } from './EstimatePage';
import { NoOfferFallbackPage } from './NoOfferFallbackPage/NoOfferFallbackPage';
import { OfferTypeQuestionPage } from './OfferTypeQuestionPage';
import { QuestionsPage } from './QuestionsPage';

export const IndexPage = () => {
  useResidenceForAddress();
  const { currentStep, setCurrentStep, setUserInfoSubmitted } = useCalculatorFlowState();

  const goToAddress = () => setCurrentStep('address');
  const goToQuestions = () => {
    setUserInfoSubmitted(false);
    setCurrentStep('questions');
  };
  const goToEstimate = () => setCurrentStep('estimate');
  const goToProductTypeQuestion = () => setCurrentStep('product-type');

  switch (currentStep) {
    case 'estimate': {
      return <EstimatePage onBack={goToQuestions} />;
    }

    case 'no-offer-fallback': {
      return <NoOfferFallbackPage onBack={goToAddress} />;
    }

    case 'questions':
      return <QuestionsPage onProceed={goToEstimate} onBack={goToAddress} />;

    case 'address':
      return <AddressSearchPage onProceed={goToQuestions} onBack={goToProductTypeQuestion} />;

    case 'product-type':
    default:
      return <OfferTypeQuestionPage onProceed={goToAddress} />;
  }
};
