import round from 'lodash/round';

import { roundToNearest } from './shared';

export const findOrderOfMagnitude = (value: number) => {
  const order = Math.floor(Math.log(value) / Math.LN10 + 0.000000001);

  return 10 ** order;
};

export const roundToFractionalOrderOfMagnitude = (value: number, magnitude = 0.1) => {
  if (value <= 0) {
    return 0;
  }

  return round(roundToNearest(value, findOrderOfMagnitude(magnitude * value), 'down'), 1 / magnitude);
};
