import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { AddressInput, ElectricCarInput, ResidenceOutput } from '~src/types';

type Address = AddressInput & { id?: string };

type StateStore = {
  electricCar?: ElectricCarInput;
  setElectricCar: (value?: ElectricCarInput) => void;

  address?: Address;
  setAddress: (address: Address) => void;

  residence?: ResidenceOutput;
  setResidence: (residence?: ResidenceOutput) => void;

  defaultResidence?: Partial<ResidenceOutput>;
  setDefaultResidence: (residence?: ResidenceOutput) => void;
};

export const useResidenceState = create(
  persist<StateStore>(
    set => ({
      setAddress: newAddress => set({ address: newAddress }),

      setElectricCar: value => set({ electricCar: value }),

      setResidence: newResidence => set({ residence: newResidence }),

      setDefaultResidence: defaultResidence => set({ defaultResidence }),
    }),
    {
      name: 'residence-state',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
