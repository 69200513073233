import { BackButton } from '~src/components/BackButton';
import { SavingsWidget } from '~src/components/SavingsWidget';
import { useLocalization } from '~src/hooks/useLocalization';
import { AddressSavingsResult } from '~src/services/useCalculateAddressSavings';
import { PageProps, ProductType } from '~src/types';
import { pickSavingsApplicableOffers, pickSavingsResult } from '~src/util';

import { BookingSection } from '../BookingSection/BookingSection';
import { SuggestedProductList } from '../SuggestedProducts';

import { initialSection, outer } from './EstimateConfirmationView.module.css';

type BookingSectionProps = Pick<PageProps, 'onBack'> & {
  savings?: AddressSavingsResult;
  desiredProduct: ProductType;
};

export const EstimateConfirmationView = ({ savings, desiredProduct, onBack }: BookingSectionProps) => {
  const translate = useLocalization();

  const result = pickSavingsResult(savings, desiredProduct);
  const offers = pickSavingsApplicableOffers(savings, desiredProduct);

  return (
    <div className={outer}>
      <BackButton buttonText={translate.START_OVER_WITH_THE_QUESTIONS} onBack={onBack} />

      <div className={initialSection}>
        <SavingsWidget savingsResult={result} />

        <BookingSection />
      </div>

      <SuggestedProductList offers={offers} />
    </div>
  );
};
