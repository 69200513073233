import { Loader } from '~src/components/Loader';
import { useCalculatorFlowState } from '~src/hooks/useCalculatorFlowState';
import { useLeadOrigin } from '~src/hooks/useLeadOrigin';
import { useResidenceState } from '~src/hooks/useResidenceState';
import { useCalculateAddressSavings } from '~src/services/useCalculateAddressSavings';
import { PageProps } from '~src/types';
import { pickSavingsResult } from '~src/util';

import { ContactInformationFormView } from './ContactInformationFormView/ContactInformationFormView';
import { EstimateConfirmationView } from './EstimateConfirmationView';
import { EstimatePageNordea } from './EstimatePageNordea';

import { backgroundImage, page } from './EstimatePage.module.css';

export const EstimatePage = ({ onBack, onProceed }: PageProps) => {
  const { leadOrigin } = useLeadOrigin();

  if (leadOrigin === 'nordea') {
    return <EstimatePageNordea />;
  }

  return <EstimatePageBase onBack={onBack} onProceed={onProceed} />;
};

const EstimatePageBase = ({ onBack }: PageProps) => {
  const { address, residence, electricCar } = useResidenceState();
  const { desiredProduct, userInfoSubmitted } = useCalculatorFlowState();

  const { savings, isFetching } = useCalculateAddressSavings({
    dawaId: address?.id,
    residence,
    electricCar,
  });

  if (isFetching) {
    return <Loader />;
  }

  const savingsResult = pickSavingsResult(savings, desiredProduct);

  return (
    <div className={page}>
      <div className={backgroundImage} />
      {!userInfoSubmitted ? (
        <ContactInformationFormView onBack={onBack} savingsResult={savingsResult} />
      ) : (
        <EstimateConfirmationView onBack={onBack} desiredProduct={desiredProduct} savings={savings} />
      )}
    </div>
  );
};
