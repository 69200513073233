import { ENV, SENTRY_DSN } from './config';

const TSR_STAGING = 1.0;
const TSR_PRODUCTION = 0.01;

export const captureError = (error: unknown) => {
  if (!SENTRY_DSN) {
    console.warn('Sentry not initialized');
    console.error(error);
    return;
  }

  import('@sentry/react').then(({ captureException }) => {
    captureException(error);
  });
};

export const initializeSentry = async () => {
  if (!SENTRY_DSN) {
    console.warn('Sentry not initialized');
    return;
  }

  const { browserTracingIntegration, init, replayIntegration } = await import('@sentry/react');

  const sentryOptions = {
    dsn: SENTRY_DSN,
    environment: ENV,
    integrations: [browserTracingIntegration(), replayIntegration()],

    tracesSampleRate: ENV === 'production' ? TSR_PRODUCTION : TSR_STAGING,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  };

  init(sentryOptions);
};
