import round from 'lodash/round';

import { EnergyOptions, FuelType, ResidenceOutput, TranslationRecord } from '~src/types';

import { findOrderOfMagnitude, roundToFractionalOrderOfMagnitude } from './magnitude';
import { DEGREE_DAYS_WEIGTED, findConstantForHeating, roundToNearest } from './shared';
import { substituteTemplate } from './template';

type DisplayRange = { label: string; value: number };
type MinMaxRange = { min: number; max: number };
type Range = DisplayRange | MinMaxRange;

type ElectricityRanges = [Range, Range, Range, Range, Range];
type HeatingRanges = [MinMaxRange, MinMaxRange, MinMaxRange, MinMaxRange, MinMaxRange];

export const createElectricityRanges = (
  translate: Pick<TranslationRecord, 'MAX_ELECTRICITY_USAGE'>,
  residence?: ResidenceOutput
): ElectricityRanges => {
  const { annualUsage, fuelType } = residence?.primaryHeating ?? {};

  const isElectricHeating = fuelType === 'electricity';

  const existingElectricityUsage = isElectricHeating ? roundToFractionalOrderOfMagnitude(annualUsage ?? 0) : 0;

  return [
    { min: 1000 + existingElectricityUsage, max: 2500 + existingElectricityUsage },
    { min: 2500 + existingElectricityUsage, max: 4000 + existingElectricityUsage },
    { min: 4000 + existingElectricityUsage, max: 6000 + existingElectricityUsage },
    { min: 6000 + existingElectricityUsage, max: 8000 + existingElectricityUsage },
    {
      label: substituteTemplate(translate.MAX_ELECTRICITY_USAGE, { usage: `${8000 + existingElectricityUsage}` }),
      value: 10_000 + existingElectricityUsage,
    },
  ];
};

export const createHeatingRanges = (
  annualUsage: number,
  fuelType: FuelType,
  energyOptions?: EnergyOptions
): HeatingRanges | undefined => {
  const constant = findConstantForHeating({ fuelType }, energyOptions);

  if (!constant) {
    return undefined;
  }

  const { calorific } = constant;

  const rangeValues = findHeatingRangesByValue(annualUsage, calorific, [2, 4, 6, 8, 10, 12, 14]);

  const orderOfMagnitude = findOrderOfMagnitude(0.1 * annualUsage);
  const roundedRangeValues = rangeValues.map(value => roundToNearest(value, orderOfMagnitude));

  return formatValuesBySpread(roundedRangeValues) as HeatingRanges;
};

const findHeatingRangesByValue = (annualUsage: number, calorificValue: number, multipliers: number[]) => {
  const rangeMiddleValues = multipliers.map(value => (value * DEGREE_DAYS_WEIGTED) / calorificValue);

  const indexWithNearestValues = findNearestValueIndex(annualUsage, rangeMiddleValues);

  const lowerIndex = Math.min(Math.max(indexWithNearestValues - 2, 0), multipliers.length - 5);
  const upperIndex = Math.min(multipliers.length, 5 + lowerIndex);

  return rangeMiddleValues.slice(lowerIndex, upperIndex);
};

const formatValuesBySpread = (values: number[]): MinMaxRange[] => {
  const spread = (values[1] ?? 0) - (values[0] ?? 0);
  const halfSpread = 0.5 * spread;

  return values.map((middleValue): MinMaxRange => {
    const min = roundToFractionalOrderOfMagnitude(middleValue - halfSpread);
    const max = roundToFractionalOrderOfMagnitude(middleValue + halfSpread);
    const rounding = middleValue >= 20 ? 0 : 1;

    return { min: round(min, rounding), max: round(max, rounding) };
  });
};

const findNearestValueIndex = (matchValue: number, searchValues: number[]) => {
  const subtractedValues = searchValues.map(value => Math.abs(value - matchValue));

  return subtractedValues.indexOf(Math.min(...subtractedValues));
};
