import { CurvedArrowIcon } from '~src/assets/icons';
import { BookCallButton } from '~src/components/Button';
import { useCalculatorFlowState } from '~src/hooks/useCalculatorFlowState';
import { useLocalization } from '~src/hooks/useLocalization';

import {
  arrowPrompt,
  bookingButton,
  bookingButtonWrapper,
  bookingContainer,
  bookingSection,
  proceedText,
  title,
} from './BookingSection.module.css';

export const BookingSection = () => {
  const translate = useLocalization();

  const { userInfoSubmitted } = useCalculatorFlowState();

  return (
    <div className={bookingSection}>
      <h1 className={title}>{translate.CONSIDER_BOOKING}</h1>

      <div className={bookingContainer}>
        <div className={bookingButtonWrapper}>
          <p className={proceedText}>{translate.PROCEED_IMMEDIATELY}</p>
          <div className={arrowPrompt}>
            <CurvedArrowIcon />
          </div>

          <BookCallButton className={bookingButton} disabled={!userInfoSubmitted} />
        </div>

        <p>{translate.THANK_YOU_FOR_YOUR_INTEREST}</p>
        <p>{translate.YOU_WILL_RECEIVE_AN_OFFER}</p>
      </div>
    </div>
  );
};
