import round from 'lodash/round';
import uniqBy from 'lodash/uniqBy';

import {
  FurnaceIcon,
  GasIcon,
  HeatPumpAndSolarIcon,
  HeatPumpIcon,
  Level1of5Icon,
  Level2of5Icon,
  Level3of5Icon,
  Level4of5Icon,
  Level5of5Icon,
  LightningIcon,
  OilIcon,
  RadiatorIcon,
  SolarIcon,
  StrawIcon,
} from '~src/assets/icons';
import { useEnergyOptions } from '~src/services/useEnergyOptions';
import { EnergyOptions, FuelType, ResidenceOutput } from '~src/types';
import { createElectricityRanges, createHeatingRanges, estimatePrimaryHeating, fuelOptions } from '~src/util';

import { useLocalization } from '../../hooks/useLocalization';
import { Answers, AnswerType, Question, QuestionOption } from './types';
import { fuelTypeFromAnswers, productTypeFromAnswers } from './util';

const DEFAULT_FUEL = 'gas';

export const useOfferQuestions = (residence?: ResidenceOutput, defaultResidence?: Partial<ResidenceOutput>) => {
  const translate = useLocalization();
  const energyOptions = useEnergyOptions();

  const residenceFuelType = residence?.primaryHeating?.fuelType;
  const defaultFuelType = defaultResidence?.primaryHeating?.fuelType;

  const getQuestions = (answers: Answers = {}) => {
    const productType = productTypeFromAnswers(answers);
    const fuelType = (fuelTypeFromAnswers(answers) ?? residenceFuelType) as FuelType | undefined;

    const initialQuestions = getInitialQuestions(translate, fuelType, defaultFuelType);

    const heatPumpQuestions = [
      ...initialQuestions,
      ...getHeatPumpQuestions(
        translate,
        residence,
        fuelType,
        answers.heatConsumption?.value,
        answers.electricityConsumption?.value,
        energyOptions
      ),
    ];

    const solarQuestions = [
      ...initialQuestions,
      ...getSolarQuestions(translate, residence, answers.electricityConsumption?.value),
    ];

    const allQuestions = uniqBy([...heatPumpQuestions, ...solarQuestions], 'key');

    switch (productType) {
      case 'both': {
        return allQuestions;
      }
      case 'heatPump': {
        return heatPumpQuestions;
      }
      case 'solar': {
        return solarQuestions;
      }

      default:
        return initialQuestions;
    }
  };

  return {
    getQuestions,
  };
};

export const useOfferTypeQuestion = (): Question => {
  const translate = useLocalization();

  return {
    key: 'offerType',
    options: [
      { label: translate.HEAT_PUMP, value: 'heatPump', icon: <HeatPumpIcon /> },
      { label: translate.SOLAR_PANEL, value: 'solar', icon: <SolarIcon /> },
      { label: translate.BOTH, value: 'both', icon: <HeatPumpAndSolarIcon /> },
    ],
    text: translate.QUESTION_WHICH_PRODUCTS,
    type: 'choice',
  };
};

const getInitialQuestions = (
  translate: ReturnType<typeof useLocalization>,
  selectedFuelType?: FuelType,
  defaultFuelType?: AnswerType
): Question[] => [
  {
    key: 'fuelType',
    text: translate.QUESTION_HEAT_SOURCE,
    type: 'choice',
    options: [
      { value: 'gas', label: translate.GAS_FURNACE, icon: <GasIcon /> },
      { value: 'oil', label: translate.OIL_FURNACE, icon: <OilIcon /> },
      { value: 'electricity', label: translate.ELECTRIC_HEATING, icon: <LightningIcon /> },
      { value: 'wood', label: translate.WOOD_FURNACE, icon: <FurnaceIcon /> },
      { value: 'straw', label: translate.STRAW_FURNACE, icon: <StrawIcon /> },
      { value: 'districtHeating', label: translate.DISTRICT_HEATING, icon: <RadiatorIcon /> },
    ].map(option => ({
      ...option,
      default: option.value === defaultFuelType,
      selected: option.value === selectedFuelType,
    })),
  },
];

const getHeatPumpQuestions = (
  translate: ReturnType<typeof useLocalization>,
  residence?: ResidenceOutput,
  selectedFuelType?: FuelType,
  selectedHeatConsumption?: AnswerType,
  electricityConsumptionAnswer?: AnswerType,
  energyOptions?: EnergyOptions
): Question[] => {
  const { areaHeated, builtYear } = residence ?? {};

  const annualUsage = selectedFuelType
    ? estimatePrimaryHeating(selectedFuelType, areaHeated ?? 0, builtYear ?? 0, fuelOptions)?.annualUsage
    : 0;

  const { denominator } = fuelOptions[selectedFuelType ?? DEFAULT_FUEL] ?? {};

  return [
    {
      key: 'heatConsumption',
      text: translate.QUESTION_HEAT_CONSUMPTION,
      type: 'choice',
      options: annualUsage
        ? createHeatingRanges(annualUsage, selectedFuelType ?? DEFAULT_FUEL, energyOptions)?.map(
            ({ min, max }, index): QuestionOption => {
              const value = round(0.5 * (min + max));
              const defaultValue = index === 2;

              return {
                value,
                label: `${min} - ${max} ${denominator}`,
                default: defaultValue,
                selected: selectedHeatConsumption ? selectedHeatConsumption === value : defaultValue,
                icon: <ConsumptionIcon level={Math.min(index + 1, 5)} />,
              };
            }
          )
        : [],
    },
    getElectricityConsumptionQuestion(translate, residence, electricityConsumptionAnswer),
  ];
};

const getSolarQuestions = (
  translate: ReturnType<typeof useLocalization>,
  residence?: ResidenceOutput,
  electricityConsumptionAnswer?: AnswerType
): Question[] => [
  getElectricityConsumptionQuestion(translate, residence, electricityConsumptionAnswer),
  {
    key: 'electricCar',
    text: translate.QUESTION_ELECTRIC_CAR,
    type: 'boolean',
  },
];

const getElectricityConsumptionQuestion = (
  translate: ReturnType<typeof useLocalization>,
  residence?: ResidenceOutput,
  electricityConsumptionAnswer?: AnswerType
): Question => {
  const ranges = createElectricityRanges(translate, residence);

  return {
    key: 'electricityConsumption',
    text: translate.QUESTION_ELECTRICITY_CONSUMPTION,
    type: 'choice',
    options: ranges.map((entry, index) => {
      if ('label' in entry) {
        const { value, label } = entry;

        return {
          value,
          label,
          default: false,
          selected: electricityConsumptionAnswer ? electricityConsumptionAnswer === value : false,
          icon: <ConsumptionIcon level={Math.min(index + 1, 5)} />,
        };
      }

      const { min, max } = entry;

      const value = round(0.5 * (min + max));
      const defaultValue = index === 2;

      return {
        value,
        label: `${min} - ${max} kWh`,
        default: defaultValue,
        selected: electricityConsumptionAnswer ? electricityConsumptionAnswer === value : defaultValue,
        icon: <ConsumptionIcon level={Math.min(index + 1, 5)} />,
      };
    }),
  };
};

const ConsumptionIcon = ({ level }: { level?: number }) => {
  switch (level) {
    case 2:
      return <Level2of5Icon />;
    case 3:
      return <Level3of5Icon />;
    case 4:
      return <Level4of5Icon />;
    case 5:
      return <Level5of5Icon />;
    case 1:
    default:
      return <Level1of5Icon />;
  }
};
