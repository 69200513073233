export const useLocalization = () => translate;

const translate = {
  ANSWER_QUESTIONS: 'Besvar spørgsmål',

  BACK_TO_PREVIOUS_QUESTION: 'Tilbage til tidligere spørgsmål',
  BOOK_ADVISOR: 'Book energirådgivning',
  BOOK_A_MEETING_FALLBACK: 'Book i stedet en samtale med en af vores energirådgivere, så hjælper de dig videre.',
  BOTH: 'Begge',

  CHOOSE_NEW_ADDRESS: 'Indtast ny adresse',
  CHOOSE_PRODUCT: 'Vælg produkt',
  CONSIDER_BOOKING: 'Tak! Du kan nu booke et møde med en erfaren energirådgiver',
  CONTACT_INFORMATION_DISCLAIMER:
    'Bodil sælger ikke dine kontaktoplysninger og du kan til enhver tid bede os om at slette dem igen.',
  YOU_WILL_RECEIVE_AN_OFFER: 'Vi ringer til dig inden for to uger og følger op med udgangspunkt i din rapport.',

  DATA_FOUND_FROM_PUBLIC_SOURCES: 'Forvalgt fra offentlig data',
  DISTRICT_HEATING_DISCLAIMER:
    'Fordi du har fjernvarme, kan vi ikke lave et automatisk overslag på en varmepumpeløsning.',
  DISTRICT_HEATING: 'Fjernvarme',
  DONE: 'Færdig',

  EMAIL: 'Email',
  EMAIL_REQUIRED: 'Indtast email',
  ENERGY_REPORT:
    'Med din personlige energiberegning får du et klart overblik over både pris og besparelser ved at skifte til en varmepumpe eller installere solceller. Indtast dine informationer nedenfor for at få tilsendt din beregning.',
  ELECTRIC_HEATING: 'Elvarme',

  FOLLOWING_PRODUCTS: 'følgende produkter',

  GAS_FURNACE: 'Naturgasfyr',

  HEAT_PUMP: 'Varmepumpe',
  HEAT_PUMP_AND_SOLAR: 'Varmepumpe og solceller',
  HEAT_PUMP_BAD_CASE: '*Varmepumpe kan desværre ikke betale sig.',

  INFLATION_DISCLAIMER: 'Gennemsnit over 15 år med 2% prisinflation.',

  NEXT: 'Frem',
  NAME: 'Navn',
  NAME_REQUIRED: 'Indtast navn',
  NO: 'Nej',
  CALCULATION_NOT_POSSIBLE: 'Vi kan desværre ikke beregne din besparelse uden mere information.',

  OIL_FURNACE: 'Oliefyr',

  PREVIOUS: 'Tilbage',
  PHONE_NUMBER: 'Telefonnummer',
  PHONE_NUMBER_REQUIRED: 'Indtast telefonnummer',
  PRICE: 'Pris',
  PRICE_ALL_INCLUSIVE: 'Pris alt inklusive',
  PROCEED_IMMEDIATELY: 'Vil du videre med det samme?',
  PROFIT_AFTER_X_YEARS: 'Gevinst efter {years} år',
  PAYBACK_TIME: 'Tilbagebetalingstid',

  QUESTION_WHICH_PRODUCTS: 'Hvad er du interesseret i?',
  QUESTION_HEAT_SOURCE: 'Hvilken varmekilde har du?',
  QUESTION_HEAT_CONSUMPTION: 'Hvad er dit varmeforbrug?',
  QUESTION_ELECTRICITY_CONSUMPTION: 'Hvor stort er dit totale elforbrug ca.?',
  QUESTION_ELECTRIC_CAR: 'Har du elbil eller regner du med at få det?',

  SOLAR_PANEL: 'Solceller',
  SOLAR_PANEL_INSTALLATION: 'Solcelleanlæg',
  RECEIVE_REPORT: 'Sådan får du tilsendt din personlige beregning',
  SEE_CALCULATION: 'Ja tak',
  STRAW_FURNACE: 'Halmfyr',
  SUGGEST_SOLAR_PANELS: 'Vis solcelleløsning',
  START_OVER_WITH_THE_QUESTIONS: 'Start forfra på spørgsmålene',
  SAVINGS: 'Besparelse',
  SEE_YOUR_PRODUCTS: 'Se dine produkter',

  TYPE_ADDRESS: 'Indtast adresse',
  THANK_YOU: 'Tak',
  THANK_YOU_FOR_YOUR_INTEREST:
    'Tak for din interesse i energioptimering med Bodil Energi! Du modtager dit detaljerede overslag per email.',
  TOTAL_PRICE_ALL_INCLUSIVE: 'Samlet pris alt inklusive',
  TOAST_ERROR_NETWORK: '',
  TOAST_WARNING_MISSING_ADDRESS_RESIDENCE: 'Der mangler oplysninger om adresse og/eller bolig.',

  WOOD_FURNACE: 'Pillefyr',
  WHAT_IS_YOUR_ADDRESS: 'Hvad er din addresse?',

  YOU_CAN_SAVE: 'Du kan spare cirka {amount} om året ved at skifte til {solution}.',
  YES: 'Ja',
  YEARLY_SAVINGS: 'Årlig besparelse',
  YEARLY_CO2_SAVINGS: 'Årlig CO₂ besparelse',

  MAX_ELECTRICITY_USAGE: 'Mere end {usage} kWh',
};
