/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';
import { HTMLProps, ReactNode } from 'react';

import { hover, hoverWithShadow } from '~src/style/shared.module.css';

import { button, callToAction, link } from './button.module.css';

type ButtonProps = HTMLProps<HTMLButtonElement> & {
  children?: ReactNode;
  variant?: 'default' | 'link' | 'callToAction';
  type?: 'button' | 'submit' | 'reset';
};

export const Button = ({
  children,
  type = 'button',
  variant = 'default',
  className,
  disabled,
  ...rest
}: ButtonProps) => (
  <button
    {...rest}
    disabled={disabled}
    type={type}
    className={clsx(
      className,
      button,
      !disabled && hover,
      variant === 'link' && link,
      variant !== 'link' && !disabled && hoverWithShadow,
      variant === 'callToAction' && callToAction
    )}
  >
    {children}
  </button>
);
