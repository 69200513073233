import clsx from 'clsx';
import { useState } from 'react';

import { CheckmarkIcon, InfoIcon, XIcon } from '~src/assets/icons';
import { Button } from '~src/components/Button';
import { useLocalization } from '~src/hooks';

import { Tooltip } from '../Tooltip';
import { AnswerType, Question } from './types';

import {
  answerButton,
  answerContainer,
  answerText,
  answerTooltip,
  defaultAnswerIcon,
  selectedAnswer,
} from './carousel.module.css';

type AnswerProps = {
  question: Question;
  onAnswer: (value: string | number | boolean) => void;
};

export const CarouselAnswer = ({ question, onAnswer }: AnswerProps) => {
  const translate = useLocalization();
  const [temporaryAnswer, setTemporaryAnswer] = useState<AnswerType>();

  switch (question.type) {
    case 'boolean':
      return (
        <div className={answerContainer}>
          <Button onClick={() => onAnswer(true)} className={answerButton}>
            <CheckmarkIcon />

            <span className={answerText}> {translate.YES}</span>
          </Button>
          <Button onClick={() => onAnswer(false)} className={answerButton}>
            <XIcon />

            <span className={answerText}> {translate.NO}</span>
          </Button>
        </div>
      );
    case 'number':
      return (
        <div className={answerContainer}>
          <input type='number' onChange={e => setTemporaryAnswer((e.target as HTMLInputElement).value)} />
          <Button
            disabled={!temporaryAnswer}
            onClick={() => onAnswer(Number(temporaryAnswer))}
            className={answerButton}
          >
            {translate.DONE}
          </Button>
        </div>
      );
    default:
      return (
        <div className={answerContainer}>
          {question.options?.map(({ label, value, default: isDefaultAnswer, selected, icon }) => (
            <Tooltip
              className={answerTooltip}
              key={label}
              tooltipText={isDefaultAnswer ? translate.DATA_FOUND_FROM_PUBLIC_SOURCES : undefined}
            >
              <Button
                key={label}
                onClick={() => onAnswer(value)}
                className={clsx(answerButton, selected && selectedAnswer)}
              >
                {icon}

                <span className={answerText}>{label}</span>

                {isDefaultAnswer && (
                  <div className={defaultAnswerIcon}>
                    <InfoIcon />
                  </div>
                )}
              </Button>
            </Tooltip>
          ))}
        </div>
      );
  }
};
