import last from 'lodash/last';
import { ReactNode } from 'react';

import { CalendarIcon, Co2Icon, MoneyBagIcon, PiggyBankIcon, PriceTagIcon } from '~src/assets/icons';
import { Highlights, TextWithHighlight } from '~src/components/TextWithHighlights';
import { useLocalization } from '~src/hooks';
import { SavingsResult } from '~src/types';
import {
  calculatePaybackTime,
  formatCurrency,
  formatProducts,
  productTypeFromSavingsResult,
  roundToFractionalOrderOfMagnitude,
  substituteTemplate,
} from '~src/util';

import style from './SavingsWidget.module.css';

const NUMBER_OF_YEARS_MEASURED = 15;

type SavingsWidgetProps = {
  savingsResult?: SavingsResult;
  showSavingsText?: boolean;
};

export const SavingsWidget = ({ savingsResult, showSavingsText }: SavingsWidgetProps) => {
  const translate = useLocalization();

  const { savings, products } = savingsResult ?? {};

  const { saved } = savings ?? {};
  const { price: productsPrice = 0 } = products ?? {};
  const { price: savedPerYear = 0, emission = 0, priceByYear } = saved ?? {};

  const roundedSavedPerYear = roundToFractionalOrderOfMagnitude(savedPerYear);

  const { paybackFormatted } = calculatePaybackTime(
    productsPrice,
    years => priceByYear?.[years] ?? last(priceByYear) ?? 0
  );

  const totalProfit = roundedSavedPerYear * NUMBER_OF_YEARS_MEASURED - productsPrice;
  const roundedProfit = totalProfit > 0 ? roundToFractionalOrderOfMagnitude(totalProfit) : 0;

  const { price = 0 } = savingsResult?.savings?.saved ?? {};

  const formattedSavings = formatCurrency(roundToFractionalOrderOfMagnitude(price));
  const productType = productTypeFromSavingsResult(savingsResult);

  const productSavingsText = substituteTemplate(translate.YOU_CAN_SAVE, {
    amount: `${formattedSavings}`,
    solution: formatProducts(productType, translate).toLowerCase(),
  });

  const productHighlights: Highlights = { [formattedSavings]: 'green' };

  return (
    <div className={style.container}>
      {showSavingsText && (
        <h2 className={style.title}>
          <TextWithHighlight text={productSavingsText} highlights={productHighlights} />
        </h2>
      )}

      <Entry label={translate.TOTAL_PRICE_ALL_INCLUSIVE}>
        <PriceTagIcon />
        {formatCurrency(productsPrice, { decimals: 0 })}
      </Entry>

      <Entry label={translate.YEARLY_SAVINGS} disclaimer={translate.INFLATION_DISCLAIMER}>
        <PiggyBankIcon />
        {formatCurrency(roundedSavedPerYear)}
      </Entry>

      {roundedProfit > 0 && (
        <Entry
          label={substituteTemplate(translate.PROFIT_AFTER_X_YEARS, { years: NUMBER_OF_YEARS_MEASURED.toString() })}
        >
          <MoneyBagIcon />
          {formatCurrency(roundedProfit, { decimals: 0 })}
        </Entry>
      )}

      <Entry label={translate.YEARLY_CO2_SAVINGS}>
        <Co2Icon />
        {roundToFractionalOrderOfMagnitude(emission)} kg
      </Entry>

      <Entry label={translate.PAYBACK_TIME}>
        <CalendarIcon />
        {paybackFormatted}
      </Entry>
    </div>
  );
};

type EntryProps = {
  label: string;
  children?: ReactNode;
  disclaimer?: string;
};

const Entry = ({ label, children, disclaimer }: EntryProps) => (
  <div className={style.entryWrapper}>
    <div className={style.entryBackground}>
      <p className={style.label}>{label}</p>

      <h3 className={style.entry}>{children}</h3>
    </div>

    {disclaimer && <p className={style.entryDisclaimer}>{disclaimer}</p>}
  </div>
);
