import { toast } from 'react-toastify';

import { captureError } from '~src/sentry';
import { TranslationRecord } from '~src/types';

export const handleNetworkError = (translate: TranslationRecord) => (error: unknown) => {
  captureError(error);

  toast.error(translate.TOAST_ERROR_NETWORK);
};
