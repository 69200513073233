import { BackToAddressPage } from '~src/components/BackToAddressPage';
import { BookCallButton } from '~src/components/Button';
import { useLocalization } from '~src/hooks/useLocalization';
import { PageProps } from '~src/types';

import { bookingSection, page } from './NoOfferFallbackPage.module.css';

export const NoOfferFallbackPage = ({ onBack }: PageProps) => {
  const translate = useLocalization();

  return (
    <div className={page}>
      <BackToAddressPage onBack={onBack} />

      <div className={bookingSection}>
        <p>{translate.CALCULATION_NOT_POSSIBLE}</p>

        <p>{translate.BOOK_A_MEETING_FALLBACK}</p>

        <BookCallButton />
      </div>
    </div>
  );
};
