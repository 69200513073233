import clsx from 'clsx';

import { BOOK_MEETING_URL } from '~src/config';
import { useLocalization } from '~src/hooks';

import { Button } from './Button';

import { bookCallButton } from './button.module.css';

type BookCallButtonprops = {
  disabled?: boolean;
  className?: string;
};

export const BookCallButton = ({ disabled, className }: BookCallButtonprops) => {
  const translate = useLocalization();

  if (!BOOK_MEETING_URL) {
    return null;
  }

  const navigateToBooking = () => {
    globalThis.window.location.href = BOOK_MEETING_URL;
  };

  return (
    <Button
      disabled={disabled}
      className={clsx([className, bookCallButton])}
      variant='callToAction'
      onClick={navigateToBooking}
    >
      {translate.BOOK_ADVISOR}
    </Button>
  );
};
