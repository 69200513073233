export const substituteTemplate = (input: string, substitutions: Record<string, string | undefined> = {}): string => {
  const entries = Object.entries(substitutions);
  let returnValue = input;

  entries.forEach(([key, value]) => {
    returnValue = returnValue.replace(new RegExp(`{${key}}`, 'g'), value ?? '');
  });

  return returnValue;
};
