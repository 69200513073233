import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { ProductType } from '~src/types';

type Step = 'address' | 'estimate' | 'no-offer-fallback' | 'questions' | 'product-type';

type StateStore = {
  userInfoSubmitted: boolean;
  setUserInfoSubmitted: (value: boolean) => void;

  currentStep: Step;
  setCurrentStep: (state: Step) => void;

  desiredProduct: ProductType;
  setDesiredProduct: (product?: ProductType) => void;
};

export const useCalculatorFlowState = create(
  persist<StateStore>(
    set => ({
      userInfoSubmitted: false,
      setUserInfoSubmitted: userInfoSubmitted => set({ userInfoSubmitted }),

      currentStep: 'product-type',
      setCurrentStep: step => set({ currentStep: step }),

      desiredProduct: 'both',
      setDesiredProduct: product => set({ desiredProduct: product }),
    }),
    {
      name: 'application-state',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
