import { StrictMode } from 'react';

import { ErrorBoundary } from './components/ErrorBoundary';
import { DefaultLayout } from './components/Layout';
import { ThirdPartyScripts } from './components/ThirdPartyScripts';
import { IndexPage } from './pages/IndexPage';
import { queryClient, QueryClientProvider } from './services/client';

import './style/main.css';
import './style/fonts.css';

export const Main = () => (
  <StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ThirdPartyScripts />

        <DefaultLayout>
          <IndexPage />
        </DefaultLayout>
      </QueryClientProvider>
    </ErrorBoundary>
  </StrictMode>
);
