import { BackButton } from '~src/components/BackButton';
import { Highlights, TextWithHighlight } from '~src/components/TextWithHighlights';
import { useCalculatorFlowState, useContactInformationFormSubmit, useLocalization } from '~src/hooks';
import { PageProps, SavingsResult } from '~src/types';
import {
  formatCurrency,
  formatProducts,
  productTypeFromSavingsResult,
  roundToFractionalOrderOfMagnitude,
} from '~src/util';
import { substituteTemplate } from '~src/util/template';

import { ContactInformationForm } from './ContactInformationForm';

import { formSection, outerWrapper, subtitle, title } from './ContactInformationFormView.module.css';

type ContactInformationFormViewProps = Pick<PageProps, 'onBack'> & {
  savingsResult?: SavingsResult;
};

export const ContactInformationFormView = ({ onBack, savingsResult }: ContactInformationFormViewProps) => {
  const translate = useLocalization();

  const { desiredProduct } = useCalculatorFlowState();

  const onSubmit = useContactInformationFormSubmit(savingsResult);

  const { price = 0 } = savingsResult?.savings?.saved ?? {};

  const formattedSavings = formatCurrency(roundToFractionalOrderOfMagnitude(price));

  const productHighlights: Highlights = { [formattedSavings]: 'green' };

  const products = productTypeFromSavingsResult(savingsResult);

  const heatPumpBadCase = desiredProduct === 'both' && products === 'solar';

  const productSavingsText = substituteTemplate(translate.YOU_CAN_SAVE, {
    amount: `${formattedSavings}`,
    solution: formatProducts(products, translate).toLowerCase(),
  });

  return (
    <div className={outerWrapper}>
      <BackButton buttonText={translate.START_OVER_WITH_THE_QUESTIONS} onBack={onBack} />

      <div className={formSection}>
        {!!savingsResult && (
          <>
            <h1 className={title}>
              <TextWithHighlight text={productSavingsText} highlights={productHighlights} />
            </h1>
            {heatPumpBadCase && <h4 className={subtitle}>{translate.HEAT_PUMP_BAD_CASE}</h4>}
          </>
        )}

        <ContactInformationForm onSubmit={onSubmit} />
      </div>
    </div>
  );
};
