import { black, darkGreen, green, orange } from './TextWithHighlights.module.css';

type color = 'green' | 'black' | 'orange' | 'darkGreen';

export type Highlights = Record<string, color | undefined>;

type TextWithHighlightProps = { text: string; highlights: Highlights };

export const TextWithHighlight = ({ text, highlights }: TextWithHighlightProps) => {
  const entries = Object.entries(highlights);

  let stringWithDividers = text;

  entries.forEach(([string]) => {
    stringWithDividers = stringWithDividers.replace(new RegExp(string, 'g'), `|||${string}|||` ?? '');
  });

  const splitString = stringWithDividers.split('|||');

  return splitString.map(string => (
    <span className={getClassNameForColor(highlights[string])} key={string}>
      {string}
    </span>
  ));
};

const getClassNameForColor = (color?: color) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (color) {
    case 'black':
      return black;
    case 'green':
      return green;
    case 'orange':
      return orange;
    case 'darkGreen':
      return darkGreen;
    default:
      return '';
  }
};
