import { SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useInsertAndSendOffers } from '~src/services/useInsertAndSendOffers';
import { ContactInformation, ResidenceInput, SavingsResult } from '~src/types';
import { productsToHeatPumpOfferInput, productsToSolarOfferInput, toValidAddressInput } from '~src/util';

import { useAnalytics } from './useAnalytics';
import { useCalculatorFlowState } from './useCalculatorFlowState';
import { useDataState } from './useDataState';
import { useGoogleClickID } from './useGoogleClickID';
import { useLeadOrigin } from './useLeadOrigin';
import { useLocalization } from './useLocalization';
import { useResidenceState } from './useResidenceState';
import { useUTMFields } from './useUTMFields';

export const useContactInformationFormSubmit = (savingsResult?: SavingsResult) => {
  const translate = useLocalization();
  const { contactInfoSubmitted } = useAnalytics();

  const { leadOrigin } = useLeadOrigin();
  const { address, residence: residenceState } = useResidenceState();
  const { desiredProduct, setUserInfoSubmitted } = useCalculatorFlowState();
  const { setUserInfo } = useDataState();

  const { insertAndSendOffers } = useInsertAndSendOffers();

  const showBoth = desiredProduct === 'both';
  const showSolar = showBoth || desiredProduct === 'solar';
  const showHeatPump = showBoth || desiredProduct === 'heatPump';

  const googleClickId = useGoogleClickID();
  const utmFields = useUTMFields();

  const onSubmit: SubmitHandler<ContactInformation> = async formValues => {
    setUserInfoSubmitted(true);
    setUserInfo(formValues);
    contactInfoSubmitted(formValues.email);

    const addressInput = toValidAddressInput(address);

    if (!addressInput || !residenceState) {
      toast.warn(translate.TOAST_WARNING_MISSING_ADDRESS_RESIDENCE);
      return;
    }

    const { name, email, phoneNumber } = formValues;

    // eslint-disable-next-line prefer-const
    let [lastName, ...reversedListFirstNames] = (name ?? '').split(' ').reverse();
    let firstName = reversedListFirstNames.reverse().join(' ');

    if (!firstName) {
      firstName = name;
      lastName = '';
    }

    const solarOffer = showSolar ? productsToSolarOfferInput(savingsResult?.products) : undefined;
    const heatPumpOffer = showHeatPump ? productsToHeatPumpOfferInput(savingsResult?.products) : undefined;

    const residence: ResidenceInput = {
      ...residenceState,
      primaryHeating: {
        annualUsage: 0,
        fuelType: 'gas',
        ...residenceState.primaryHeating,
      },
      secondaryHeating: {
        annualUsage: 0,
        fuelType: 'wood',
        ...residenceState.secondaryHeating,
      },
    };

    await insertAndSendOffers({
      address: addressInput,
      residence,
      customer: {
        email,
        firstName,
        lastName,
        phoneNumber,
        leadOrigin,
        googleClickId,
        utmFields,
      },
      heatPumpOffers: heatPumpOffer ? [heatPumpOffer] : [],
      solarOffers: solarOffer ? [solarOffer] : [],
    });
  };

  return onSubmit;
};
