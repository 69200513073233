import clsx from 'clsx';
import { ReactNode } from 'react';

import { tooltip, tooltipWrapper } from './Tooltip.module.css';

type TooltipProps = {
  children: ReactNode;
  tooltipText?: string;
  className?: string;
};

export const Tooltip = ({ children, tooltipText, className }: TooltipProps) =>
  tooltipText ? (
    <div className={tooltipWrapper}>
      {children}

      <p className={clsx(className, tooltip)}>{tooltipText}</p>
    </div>
  ) : (
    children
  );
