import { AddressSavingsResult, ApplicableProducts, ProductType } from '~src/types';

export const pickSavingsResult = (savings: AddressSavingsResult, desiredProduct: ProductType) => {
  const { results } = savings ?? {};

  if (desiredProduct === 'both') {
    return results?.[2];
  }

  if (desiredProduct === 'solar') {
    return results?.[1];
  }

  return results?.[0];
};

export const pickSavingsApplicableOffers = (
  savings: AddressSavingsResult,
  desiredProduct: ProductType
): ApplicableProducts => {
  const { results } = savings ?? {};

  const heatPumpOffer = results?.[0].products;
  const solarOffer = results?.[1].products;

  if (desiredProduct === 'both') {
    return { heatPumpOffer, solarOffer };
  }

  if (desiredProduct === 'solar') {
    return { solarOffer };
  }

  return { heatPumpOffer };
};

export const productTypeFromSavingsResult = (savingsResult: ReturnType<typeof pickSavingsResult>): ProductType => {
  const hasHeatPump = !!savingsResult?.products.heatPumpOutdoorUnit;
  const hasSolar = !!savingsResult?.products.solarPanel;

  if (hasHeatPump && hasSolar) {
    return 'both';
  }

  if (hasSolar) {
    return 'solar';
  }

  return 'heatPump';
};
