import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { Answers } from '~src/components/QuestionCarousel';

type StateStore = {
  answers: Answers;
  setAnswers: (answers?: Answers) => void;
};

export const useCalculatorAnswersState = create(
  persist<StateStore>(
    set => ({
      answers: {},
      setAnswers: answers => set({ answers }),
    }),
    {
      name: 'answer-state',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
