import { hydrateRoot } from 'react-dom/client';

import { Main } from './main';
import { initializeSentry } from './sentry';

const rootElement = document.querySelector('#root');

if (rootElement) {
  initializeSentry().catch(console.error);
  hydrateRoot(rootElement, <Main />);
}
