import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { ContactInformation } from '~src/types';

type StateStore = {
  userInfo?: ContactInformation;
  setUserInfo: (userInfo: ContactInformation) => void;
};

export const useDataState = create(
  persist<StateStore>(
    set => ({
      setUserInfo: userInfo => set({ userInfo }),
    }),
    {
      name: 'application-state',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
